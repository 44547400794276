import React from "react";
import Header from "../components/Header/Header";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Portfolio from "../components/Portfolio/Portfolio";
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

type Props = {
  data: any;
};

const IndexPage = ({ data }: Props) => {

  const { title, keywords, description } = data.contentYaml;

  return (
    <>
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
      <Nav />
      <Header />
      <Portfolio />
      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
{
  contentYaml(
    fields: {fileName: {eq: "settings-site-metadata"}, parentFolder: {eq: "content"}}
  ) {
    title
    keywords
    description
  }
}
`